<div class="container">
    <div style="margin: 0 5%;" fxLayout="column" fxLayoutGap="1vh" fxLayoutAlign="space-around center">
        <div fxFlex fxLayout="row" fxLayoutGap="2vw" class="filters" fxLayoutAlign="end end">
            <mat-form-field fxFlex style="width:100%" appearance="outline" *ngIf="isAdmin">
                <mat-label>{{'Flows' | translate}}</mat-label>
                <mat-select (selectionChange)="getVersions(selectedInstance)" [(ngModel)]="selectedInstance">
                    <mat-option *ngFor="let instance of instances | keyvalue" [value]="instance.value[0]">
                        {{instance.value[1]}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex style="width:100%" appearance="outline">
                <mat-label>{{'Versions' | translate}}</mat-label>
                <mat-select multiple [(ngModel)]="selectedVersions"
                    (selectionChange)="getUsers(selectedInstance, selectedVersions)">
                    <mat-option *ngFor="let version of versions" [value]="version">
                        {{version}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex style="width:100%" appearance="outline">
                <mat-label>{{'Users' | translate}}</mat-label>
                <mat-select [(ngModel)]="selectedUser" (selectionChange)="search()">
                    <mat-option value="general">{{'General Data' | translate}}</mat-option>
                    <mat-option *ngFor="let user of users | keyvalue" [value]="user.key">
                        {{user.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div fxLayout="column" fxLayoutGap="1vh" fxFlex>
                <div fxFlex fxLayout="row" fxLayoutGap="0.5vw">
                    <mat-form-field fxFlex appearance="outline">
                        <mat-label fxFlex>{{'From Date' | translate}}</mat-label>
                        <input matInput [(ngModel)]="fromDateFilter" (ngModelChange)="search()"
                            [matDatepicker]="fromDate">
                        <mat-hint>DD/MMM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="fromDate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #fromDate></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field fxFlex appearance="outline">
                        <mat-label fxFlex>{{'To Date' | translate}}</mat-label>
                        <input matInput [(ngModel)]="toDateFilter" (ngModelChange)="search()" [matDatepicker]="toDate">
                        <mat-hint>DD/MMM/YYYY</mat-hint>
                        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                        <mat-datepicker #toDate></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div fxFlex class="stats" fxLayout="column" fxLayoutGap="3vh" *ngIf="searchData?.length">
            <div fxFlex fxLayout="row" fxLayoutGap="1vw">
                <div fxFlex="50" fxLayout="column" fxLayoutAlign="start center" class="box">
                    <span fxFlex class="title" fxGrow="0">{{'Top 5 Performers' | translate}}</span>
                    <table fxFlex fxGrow="0" mat-table *ngIf="topPerformerDataSource?.data?.length"
                        [dataSource]="topPerformerDataSource" style="width: 100%; background: transparent;">
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> {{'Teacher' | translate}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.userId.name}} </td>
                        </ng-container>
                        <ng-container matColumnDef="score">
                            <th mat-header-cell *matHeaderCellDef> {{'Score' | translate}} (%)</th>
                            <td mat-cell *matCellDef="let element"> {{element.score_percentage}} </td>
                        </ng-container>
                        <ng-container matColumnDef="timeSpent">
                            <th mat-header-cell *matHeaderCellDef> {{'Time Spent' | translate}} </th>
                            <td mat-cell *matCellDef="let element"> {{convertHMS(element.timespent || 1)}} </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="topPerformerdisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: topPerformerdisplayedColumns;"></tr>
                    </table>
                </div>
                <div fxFlex="50" fxLayout="column" fxLayoutAlign="start center" class="box">
                    <span fxFlex class="title" fxGrow="0">{{'Strategies Average Chart' | translate}}</span>
                    <canvas baseChart *ngIf="tagsChartData?.datasets[0]?.data.length" [options]="tagsChartOptions"
                        [data]="tagsChartData" [type]="tagsChartType">
                    </canvas>
                </div>
            </div>
            <div fxFlex fxLayout="row" fxLayoutGap="1vw">
                <div fxFlex="50" fxLayout="column" fxGrow="0" fxLayoutAlign="start center" class="box">
                    <span fxFlex class="title" fxGrow="0">{{'Score Chart' | translate}}</span>
                    <canvas baseChart *ngIf="scoreChartData?.datasets[0]?.data.length" [options]="scoreChartOptions"
                        [data]="scoreChartData" [type]="scoreChartType">
                    </canvas>
                </div>
                <div fxFlex="50" fxLayoutAlign="start center" fxGrow="0" class="box">
                    <canvas baseChart *ngIf="timeSpentChartData?.datasets[0]?.data.length"
                        [options]="timeSpentChartOptions" [data]="timeSpentChartData" [type]="timeSpentChartType"
                        [plugins]="timeSpentPlugin">
                    </canvas>
                </div>
            </div>
            <div fxFlex fxLayout="row" fxLayoutGap="1vw" fxLayoutAlign="center center">
                <div fxFlex="50" fxLayout="column" fxLayoutAlign="start center" class="box">
                    <span fxFlex class="title" fxGrow="0">{{'Average Score vs Gender' | translate}}</span>
                    <canvas baseChart *ngIf="scoreGenderChartData?.datasets[0]?.data.length"
                        [options]="scoreGenderChartOptions" [data]="scoreGenderChartData" [type]="scoreGenderChartType">
                    </canvas>
                </div>
            </div>
        </div>
    </div>
</div>