<div class="container">
  <div style="margin: 0 5%;" fxLayout="column" fxLayoutAlign="center center">
    <mat-form-field fxFlex class="search-input" appearance="outline">
      <mat-icon matPrefix>search</mat-icon>
      <input matInput placeholder="Search..." #searchInput>
    </mat-form-field>
    <div fxFlex fxLayout="column" fxLayoutGap="1vh" style="width:100%;">
      <div fxFlex class="tab">
        <span [class.active]="isAvailableSelected" [class.inactive]="!isAvailableSelected"
          (click)="switchTabs(true)">{{'Available' | translate}} ({{availableFlows.length}})</span>
        <span [class.inactive]="isAvailableSelected" [class.active]="!isAvailableSelected" class="past"
          (click)="switchTabs(false)">{{'Past' | translate}} ({{pastFlows.length}})</span>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="2.5vw" class="grid">
        <div *ngFor="let flow of flows" fxFlex="30" class="grid-item">
          <div fxLayout="column" class="item">
            <div fxFlex fxLayout="row" fxLayoutGap="1vh" fxLayoutAlign="space-between center" class="top-section">
              <div fxFlex fxLayoutAlign="start center" fxGrow="1"></div>
              <div fxFlex fxLayoutAlign="end center" fxShrink="0" *ngIf="flow.showMenu">
                <mat-icon [matMenuTriggerFor]="flowMenu">more_horiz</mat-icon>
              </div>
              <mat-menu #flowMenu="matMenu" xPosition="before" yPosition="above" [overlapTrigger]="false">
                <span mat-menu-item (click)="startFlow(flow)">
                  <ng-container *ngIf="!flow.hasIncompleteInstance">
                    {{'Start' | translate}}
                  </ng-container>
                  <ng-container *ngIf="flow.hasIncompleteInstance">
                    {{'Continue' | translate}}
                  </ng-container>
                </span>
                <span mat-menu-item (click)="showInstances(flow._id)">{{'Show Assessments' | translate }}</span>
              </mat-menu>
            </div>
            <div fxFlex fxLayout="column" class="middle-section">
              <div fxFlex class="title">
                {{flow.name}} <span class="version">V.{{flow.versionId}}</span>
              </div>
              <div fxlFlex class="description">
                {{flow.description}}
              </div>
              <div fxFlex class="due" *ngIf="isAvailableSelected && flow.dueDate">
                <mat-icon>
                  event</mat-icon>
                <span>{{'Due in' | translate}}</span>
                <span class="value">&nbsp;{{flow.dueDate | myFromNowPipe}}</span>
              </div>
              <div fxFlex class="due" *ngIf="!isAvailableSelected">
                <!-- TODO change if statement -->
                <ng-container *ngIf="true">
                  <mat-icon style="color: green;">
                    check_circle</mat-icon>
                  <span class="value">{{'Completed on' | translate}} {{flow.dueDate | date: 'dd MMM yyyy'}}</span>
                </ng-container>
                <ng-container *ngIf="false">
                  <mat-icon style="color: red;">
                    cancel</mat-icon>
                  <span class="value">{{'Expired on' | translate}} {{flow.dueDate | date: 'dd MMM yyyy'}}</span>
                </ng-container>
              </div>
            </div>
            <div fxFlex fxLayout="column" class="bottom-section">
              <div fxFlex fxLayout="row" class="details">
                <div fxFlex class="left-section">
                  <span>{{'Avg time' | translate}}</span><br>
                  <span class="value">
                    <ng-container *ngIf="flow.averageCompletion">
                      {{flow.averageCompletion}} {{'minutes' | translate}}
                    </ng-container>
                    <ng-container *ngIf="!flow.averageCompletion">
                      N/A
                    </ng-container>
                  </span>
                </div>
                <div fxFlex class="right-section">
                  <span>{{'Instances' | translate}}</span><br>
                  <span class="value">
                    <ng-container *ngIf="flow.isVariable">
                      {{'Variable' | translate}}
                    </ng-container>
                    <ng-container *ngIf="!flow.isVariable">
                      {{'Static' | translate}}
                    </ng-container>
                  </span>
                </div>
              </div>
              <div fxFlex class="completion">
                <div>60% <span>{{'completed' | translate}}</span>
                </div>
                <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>