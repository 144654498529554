<div class="navbar-container" [class.example-is-mobile]="mobileQuery.matches">

  <!-- Top navbar -->
  <mat-toolbar class="navbar">

    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>

    <a class="navbar-brand" [routerLink]="['/']" matTooltip="Home">
      <img src="assets/images/logo.png" class="circle z-depth-2 responsive-img activator">
      <span>
        {{'Scenarios' | translate}}
      </span>
    </a>

    <span class="navbar-spacer"></span>

    <button mat-button [matMenuTriggerFor]="userMenu">
      <mat-icon>person</mat-icon>
      <span fxShow fxHide.xs>
        {{userName}}
      </span>
    </button>
    <mat-menu #userMenu="matMenu" xPosition="before" yPosition="above" [overlapTrigger]="false">
      <a mat-menu-item (click)="openEditProfile()">
        <mat-icon>person</mat-icon>
        <span>{{'Account' | translate}}</span>
      </a>
      <!-- <a mat-menu-item (click)="resetPassword()">
        <mat-icon>lock</mat-icon>
        <span>{{'Change Password' | translate}}</span>
      </a> -->
      <a mat-menu-item (click)="onLoggedout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{'Log out' | translate}}</span>
      </a>
    </mat-menu>
  </mat-toolbar>

  <mat-sidenav-container class="navbar-sidenav-container">
    <!-- Side nav -->
    <mat-sidenav #snav [opened]="!mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" class="sidenav">
      <mat-nav-list>
        <a mat-list-item [routerLink]="['/']" [class.active]="currentRoute !== '/training'">
          <mat-icon mat-list-icon>
            dashboard
          </mat-icon>
          <p mat-line> {{'Dashboard' | translate}} </p>
        </a>
        <a mat-list-item [routerLink]="['/training']" [class.active]="currentRoute === '/training'">
          <mat-icon mat-list-icon>
            local_library
          </mat-icon>
          <p mat-line> {{'My Training' | translate}} </p>
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <!-- Main content -->
    <mat-sidenav-content class="sidenav-content">

      <app-loading-screen></app-loading-screen>

      <router-outlet></router-outlet>

    </mat-sidenav-content>
  </mat-sidenav-container>

</div>