import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { RequestService } from 'src/app/core/services/request.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutUtilsService } from 'src/app/core/services/layout-utils.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { fromEvent, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { FlowCharacterSelectionDialogComponent } from '../flow/flow-character-selection-modal/flow-character-selection-modal.component';
import { InstanceListDialogComponent } from '../instance-list-modal/instance-list-modal.component';
import { FlowService } from 'src/app/core/services/flow.service';

@Component({
  selector: 'app-flow-list',
  templateUrl: './flow-list.component.html',
  styleUrls: ['./flow-list.component.scss']
})
export class FlowListComponent implements OnInit, OnDestroy, AfterViewInit {

  public flows: any = [];
  public isAvailableSelected: boolean = true;
  public availableFlows: any = [];
  public pastFlows: any = [];

  private allAvailableFlows: any = [];
  private allPastFlows: any = [];
  private currentUser: any;
  private subscriptions: any = [];

  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(private authService: AuthenticationService, private titleService: Title, private requestService: RequestService, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, private router: Router, private dialog: MatDialog, private flowService: FlowService) {
  }

  async ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
    await this.getFlows().then((data: any) => {
      this.populateFlows(data, true);
    }).catch(error => {
      this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
    });
    await this.getPastFlows().then((data: any) => {
      this.populatePastFlows(data);
    }).catch(error => {
      this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
    });

    this.subscriptions.push(this.flowService.flowListSubject.subscribe(async value => {
      if (value) {
        await this.getFlows().then((data: any) => {
          this.populateFlows(data, true);
        }).catch(error => {
          this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
        });
        await this.getPastFlows().then((data: any) => {
          this.populatePastFlows(data);
        }).catch(error => {
          this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
        });
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        try {
          this.search(this.searchInput.nativeElement.value);
        } catch (e) { }
      })
    ).subscribe());
  }

  private populateFlows(flows: any, setFlows: boolean = false) {
    this.availableFlows = flows;
    this.allAvailableFlows = this.availableFlows;

    this.allAvailableFlows.map(flow => {
      if (flow.instances?.length === 0 || flow.submissionType === 'multiple_submission')
        flow.showMenu = true;
      else
        flow.showMenu = false;

      if (flow.instances?.length && flow.instances.find(i => i.status?.toLowerCase() === 'in progress'))
        flow.hasIncompleteInstance = true;
      else
        flow.hasIncompleteInstance = false;

      if ((flow.openingActIds.length > 1 || flow.characterIds.length > 1) && flow.submissionType === 'multiple_submission')
        flow.isVariable = true;
      else
        flow.isVariable = false;
    });

    if (setFlows)
      this.flows = this.availableFlows;
  }

  private populatePastFlows(flows: any) {
    this.pastFlows = flows;
    this.allPastFlows = this.pastFlows;

    this.allPastFlows.map(flow => {
      if (flow.instances?.length === 0 || flow.submissionType === 'multiple_submission')
        flow.showMenu = true;
      else
        flow.showMenu = false;

      if (flow.instances?.length && flow.instances.find(i => i.status?.toLowerCase() === 'in progress'))
        flow.hasIncompleteInstance = true;
      else
        flow.hasIncompleteInstance = false;

      if ((flow.openingActIds.length > 1 || flow.characterIds.length > 1) && flow.submissionType === 'multiple_submission')
        flow.isVariable = true;
      else
        flow.isVariable = false;
    });
  }

  private getFlows(setFlows: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      this.requestService.postRequest({
        isPastDueDate: false
      }, 'user/flow/' + this.currentUser._id + '/get', (data, error) => {
        if (data) {
          resolve(data.results);
        }
        if (error) {
          reject(error);
        }
      });
    });
  }

  private getPastFlows(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.requestService.postRequest({
        isPastDueDate: true
      }, 'user/flow/' + this.currentUser._id + '/get', (data, error) => {
        if (data) {
          resolve(data.results);
        }
        if (error) {
          reject(error);
        }
      });
    });
  }

  switchTabs(showAvailable: boolean) {
    this.isAvailableSelected = showAvailable;
    if (showAvailable) {
      this.flows = this.availableFlows;
    }
    else {
      this.flows = this.pastFlows;
    }
  }

  search(searchText: string) {
    searchText = searchText.toLowerCase();
    this.availableFlows = this.allAvailableFlows.filter(i => i.description.toLowerCase().indexOf(searchText) !== -1 || i.name.toLowerCase().indexOf(searchText) !== -1);
    this.pastFlows = this.allPastFlows.filter(i => i.description.toLowerCase().indexOf(searchText) !== -1 || i.name.toLowerCase().indexOf(searchText) !== -1);
    this.switchTabs(this.isAvailableSelected);
  }

  startFlow(flow: any) {
    console.log(flow);
    let findInstance = flow.instances.find(i => i.flowId._id === flow._id && i.status?.toLowerCase() === 'in progress');
    if (findInstance) {
      this.router.navigate(['flow/' + flow._id + '/instance/' + findInstance._id]);
    }
    else {
      if (flow.charactersAvailability === 'randomize' && flow.optactsAvailability === 'randomize') {
        let chosenCharacter = flow.characterIds[Math.floor(Math.random() * flow.characterIds.length)];
        let chosenOpeningAct = flow.openingActIds[Math.floor(Math.random() * flow.openingActIds.length)];
        this.createInstance(flow, chosenCharacter, chosenOpeningAct);
      }
      else {
        const dialog = this.dialog.open(FlowCharacterSelectionDialogComponent, {
          data: {
            characterList: flow.characterIds,
            showStepCharacters: flow.charactersAvailability === 'randomize' || flow.characterIds?.length === 0 ? false : true,
            openingActs: flow.openingActIds,
            showStepOpeningAct: flow.optactsAvailability === 'randomize' || flow.openingActIds?.length === 0 ? false : true,
          },
          width: 'fit-content'
        });

        dialog.afterClosed().subscribe(value => {
          if (value) {
            let chosenCharacter = [];
            let chosenOpeningAct = [];

            if (flow.charactersAvailability === 'randomize') {
              if (flow.characterIds?.length)
                chosenCharacter = flow.characterIds[Math.floor(Math.random() * flow.characterIds.length)];
            }
            else
              chosenCharacter = value[0];

            if (flow.optactsAvailability === 'randomize') {
              if (flow.openingActIds?.length)
                chosenOpeningAct = flow.openingActIds[Math.floor(Math.random() * flow.openingActIds.length)];
            }
            else
              chosenOpeningAct = value[1];

            this.createInstance(flow, chosenCharacter, chosenOpeningAct);
          }
        });
      }
    }
  }

  private createInstance(flow, chosenCharacter, chosenOpeningAct) {
    this.requestService.postRequest({
      points: 0,
      score_percentage: 0,
      versionId: flow.versionId,
      flow: flow.flow,
      status: 'in progress',
      allowReflection: flow.allowReflection,
      allowDecisionFeedback: flow.allowDecisionFeedback,
      userId: {
        _id: this.currentUser._id,
        name: this.currentUser.name
      },
      characterId: chosenCharacter,
      flowId: {
        _id: flow._id,
        name: flow.name
      },
      organizationId: flow.organizationId,
      openingActId: chosenOpeningAct
    }, 'instance', (data: any, error: any) => {
      if (data) {
        // this.router.navigate(['flow/' + data.results._id]);
        this.router.navigate(['flow/' + data.results.flowId._id + '/instance/' + data.results._id]);
      }
      else if (error) {
        this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
      }
    });
  }

  showInstances(idFlow: string) {
    const dialog = this.dialog.open(InstanceListDialogComponent, {
      data: {
        flowId: idFlow
      },
      width: '60vw',
      disableClose: true
    });
  }
}