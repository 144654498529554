import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { ChartData, ChartOptions, ChartType } from "chart.js";
import * as moment from "moment";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { LayoutUtilsService } from "src/app/core/services/layout-utils.service";
import { RequestService } from "src/app/core/services/request.service";

export interface TopPerformersModel {
    _id: string;
    score_percentage: number;
    userId: any;
    timeSpent: string;
}

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

    public currentUser: any = undefined;
    public isAdmin: boolean = false;
    public instances: any = new Map();
    public selectedInstance: string = undefined;
    public selectedVersions: string[] = [];
    public selectedUser: string = undefined;
    public versions: Set<string> = undefined;
    public users: any = new Map();
    public fromDateFilter: string = undefined;
    public toDateFilter: string = undefined;
    public topPerformerdisplayedColumns: string[] = ['name', 'score', 'timeSpent'];
    public topPerformerDataSource: any = [];
    public searchData: any = [];
    public tagsChartType: ChartType = 'polarArea';
    public tagsChartData: ChartData<'polarArea'> = {
        labels: [],
        datasets: [{
            data: [],
        }]
    };
    public tagsChartOptions: ChartOptions<'polarArea'> = {
        plugins: {
            title: {
                display: false
            },
            tooltip: {
                enabled: true,
                mode: "point",
                callbacks: {
                    label: (tooltip) => {
                        return tooltip.formattedValue + '%';
                    }
                },
            },
            legend: {
                display: true,
                position: 'top',
                labels: { color: 'black', }
            }
        },
        maintainAspectRatio: false,
        scales: {
            r: {
                ticks: {
                    callback: (value, index, ticks) => {
                        return value + '%';
                    }
                }
            }
        },
        responsive: false,
    };
    public scoreChartType: ChartType = 'bar';
    public scoreChartData: ChartData<'bar'> = {
        labels: [this.translate.instant("Highest Score"), this.translate.instant("Average Score"), this.translate.instant("Your Score"), this.translate.instant("Lowest Score")],
        datasets: [{
            data: [],
            backgroundColor: ["#cc0032", "#344c70", "#cc0032"]
        }],
    }
    public scoreChartOptions: ChartOptions<'bar'> = {
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            },
        },
        indexAxis: 'y',
        responsive: true,
    };
    public scoreGenderChartType: ChartType = 'bar';
    public scoreGenderChartData: ChartData<'bar'> = {
        labels: [],
        datasets: [{
            data: [],
            // backgroundColor: ["#cc0032", "#344c70", "#cc0032"]
        }],
    }
    public scoreGenderChartOptions: ChartOptions<'bar'> = {
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false
            },
        },
        responsive: true,
    };
    public timeSpentChartType: ChartType = 'line';
    public timeSpentChartData: ChartData<'line'> = {
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: ["#cc0032", "#344c70"]
        }],
    }
    public timeSpentPlugin = [];
    public timeSpentChartOptions: ChartOptions<'line'> = {
        plugins: {
            legend: {
                display: true,
                position: 'top'
            },
            title: {
                display: false
            },
        },
        layout: {
            padding: {
                bottom: 25
            }
        },
        responsive: true,
    };

    private timeSpentPerScoreData: any = { timeSpent: [], dates: [], characters: [] };
    private scoreGenderData: any = { count: [], score: [] };
    private strategiesData: any = [];
    private allData: any = [];
    private scoreChartRawData: any = [];

    constructor(private authService: AuthenticationService, private requestService: RequestService, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService) { }

    async ngOnInit(): Promise<void> {
        this.currentUser = this.authService.getCurrentUser();
        this.isAdmin = this.requestService.getUserRoleByUserData(this.currentUser) === 'admin';
        await this.getInstances().then((data: any) => {
            if (data.length) {
                this.allData = data;
                data.forEach(element => {
                    this.instances.set(element.flowId._id, element.flowId.name);
                });
                this.instances = [...this.instances.entries()].sort();
                this.selectedInstance = this.instances[0][0];
                this.getVersions(this.selectedInstance);
            }
        }).catch(error => {
            this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
        });
    }

    ngOnDestroy(): void {

    }

    ngAfterViewInit(): void {

    }

    private getInstances(): Promise<void> {
        return new Promise((resolve, reject) => {
            let filters: any = [
                { 'organizationId._id': { '$eq': this.requestService.orgId } },
                { 'status': { '$eq': 'completed' } }
            ];

            if (!this.isAdmin)
                filters.push({ 'userId._id': { '$eq': this.currentUser._id } });

            this.requestService.postRequest({
                page: 1, term: undefined, orderDir: 'asc', orderBy: 'flowId.name', fields: ['flowId', 'versionId', 'userId'], filter: {
                    "$and": filters
                }
            }, 'instance/search', (data, error) => {
                if (data) {
                    resolve(data.results);
                }
                if (error) {
                    reject(error);
                }
            });
        });
    }

    getVersions(flowId: string) {
        if (this.allData.length) {
            this.versions = new Set(this.allData.filter(i => i.flowId._id === flowId).map(i => i.versionId));
            this.selectedVersions = [[...this.versions][0]];
            this.getUsers(this.selectedInstance, this.selectedVersions);
        }
    }

    getUsers(flowId: string, versions: string[]) {
        if (this.allData.length) {
            this.users = new Map();
            this.allData.filter(i => i.flowId._id === flowId && versions.indexOf(i.versionId) !== -1).forEach(element => {
                this.users.set(element.userId._id, element.userId.name);
            });
            this.selectedUser = 'general';
            this.search();
        }
    }

    async search() {
        await this.searchInstances().then((data) => {
            this.searchData = data;
        }).catch(error => {
            this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
        });
        this.processData();
        this.topPerformerDataSource = [];
        await this.getTopPerformers().then((data: any) => {
            if (data?.length)
                this.topPerformerDataSource = new MatTableDataSource<TopPerformersModel>(data);
        }).catch(error => {
            this.layoutUtilsService.showNotification('Error: ' + error, this.translate.instant('Dismiss'));
        });
        this.getStrategies();
        this.getScoreChart();
        this.getTimeSpentPerScore();
        this.getScoreGender();
    }

    private searchInstances(): Promise<void> {
        let filters: any = [
            { 'organizationId._id': { '$eq': this.requestService.orgId } },
            { 'flowId._id': { '$eq': this.selectedInstance } },
            { 'versionId': { '$in': this.selectedVersions } },
            { 'status': { '$eq': 'completed' } }
        ];

        if (this.selectedUser && this.selectedUser !== 'general') {
            filters.push({ 'userId._id': { '$eq': this.selectedUser } });
        }

        if (this.fromDateFilter) {
            filters.push({ 'endedAt': { '$gte': this.fromDateFilter } });
        }

        if (this.toDateFilter) {
            filters.push({ 'endedAt': { '$lte': this.toDateFilter } });
        }

        this.searchData = [];

        return new Promise((resolve, reject) => {
            this.requestService.postRequest({
                page: 1, term: undefined, orderDir: 'asc', orderBy: 'flowId.name', fields: ['flowId', 'versionId', 'userId', 'flow', 'points', 'timespent', 'endedAt', 'characterId'], filter: {
                    "$and": filters
                }
            }, 'instance/search', (data, error) => {
                if (data) {
                    // this.searchData = data.results;
                    resolve(data.results);
                }
                if (error) {
                    reject(error);
                }
            });
        });
    }

    private getTopPerformers(): Promise<void> {
        let filters: any = [
            { 'organizationId._id': { '$eq': this.requestService.orgId } },
            { 'flowId._id': { '$eq': this.selectedInstance } },
            { 'versionId': { '$in': this.selectedVersions } },
            { 'status': { '$eq': 'completed' } }
        ];

        if (this.selectedUser && this.selectedUser !== 'general') {
            filters.push({ 'userId._id': { '$eq': this.selectedUser } });
        }

        if (this.fromDateFilter) {
            filters.push({ 'endedAt': { '$gte': this.fromDateFilter } });
        }

        if (this.toDateFilter) {
            filters.push({ 'endedAt': { '$lte': this.toDateFilter } });
        }

        return new Promise((resolve, reject) => {
            this.requestService.postRequest({
                page: 1, term: undefined, orderDir: 'desc', count: 5, order: [{ field: 'score_percentage', order: 'desc' }, { field: 'timespent', order: 'asc' }], fields: ['flowId', 'versionId', 'userId', 'score_percentage', 'timespent', 'endedAt'], filter: {
                    "$and": filters
                }
            }, 'instance/search', (data, error) => {
                if (data) {
                    resolve(data.results);
                }
                if (error) {
                    reject(error);
                }
            });
        });
    }

    private getStrategies() {
        let labels = [];
        let values = [];
        let percentages = [];
        let sum = 0;

        if (this.strategiesData && Object.keys(this.strategiesData).length) {
            Object.keys(this.strategiesData).forEach(label => {
                labels.push(label);
                values.push(this.strategiesData[label]);
            });
            sum = values.reduce((a, b) => a + b, 0);
            Object.keys(this.strategiesData).forEach(label => {
                percentages.push((this.strategiesData[label] / sum) * 100);
            });
        }

        this.tagsChartData.datasets[0].data = percentages;
        this.tagsChartData.labels = labels;
    }

    convertHMS(value) {
        if (value) {
            const sec = parseInt(value, 10); // convert value to number if it's string
            let hours: any = Math.floor(sec / 3600); // get hours
            let minutes: any = Math.floor((sec - (hours * 3600)) / 60); // get minutes
            let seconds: any = sec - (hours * 3600) - (minutes * 60); //  get seconds
            // add 0 if value < 10; Example: 2 => 02
            if (hours < 10) { hours = "0" + hours; }
            if (minutes < 10) { minutes = "0" + minutes; }
            if (seconds < 10) { seconds = "0" + seconds; }
            return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
        }
        return '';
    }

    private getScoreChart() {
        let maxScore = Math.max(...this.scoreChartRawData);
        let minScore = Math.min(...this.scoreChartRawData);
        let averageScore = this.scoreChartRawData.reduce((a, b) => a + b, 0) / this.scoreChartRawData.length;

        let myScores = [];
        this.searchData.filter(i => i.userId._id === this.currentUser._id).forEach(element => {
            myScores.push(element.points);
        });
        let myScore = Math.max(...myScores);

        if (this.scoreChartRawData.length)
            this.scoreChartData.datasets[0].data = [maxScore, averageScore, myScore, minScore];
    }

    private getCharacterRoundImage(url) {
        return new Promise((resolve, reject) => {
            let scale = 30;
            let tempCanvas = document.createElement("canvas");
            let ctx = tempCanvas.getContext("2d");
            let img = new Image();
            img.crossOrigin = "anonymous";
            img.src = url;
            img.height = scale;
            img.width = scale;
            img.style.objectFit = "fill";

            img.onload = function (e) {
                tempCanvas.width = scale;
                tempCanvas.height = scale;
                ctx.save();
                ctx.beginPath();
                ctx.arc(scale / 2, scale / 2, scale / 2, 0, Math.PI * 2, true);
                ctx.closePath();
                ctx.clip();

                ctx.drawImage(img, 0, 0, scale, scale);

                ctx.beginPath();
                ctx.arc(0, 0, 2, 0, Math.PI * 2, true);
                ctx.clip();
                ctx.closePath();
                ctx.restore();

                resolve(tempCanvas.toDataURL());
            };
        });
    }

    private processData() {
        this.searchData.forEach(async instance => {
            this.timeSpentPerScoreData.timeSpent.push(instance.timespent);
            this.timeSpentPerScoreData.dates.push(moment(instance.endedAt).format('DD-MMM-yyyy'));

            this.timeSpentPerScoreData.characters.push(await this.getCharacterRoundImage(instance.characterId.pictureLink));

            this.scoreGenderData.count[instance.characterId.gender] = !this.scoreGenderData.count[instance.characterId.gender] ? 1 : this.scoreGenderData.count[instance.characterId.gender] + 1;
            this.scoreGenderData.score[instance.characterId.gender] = !this.scoreGenderData.score[instance.characterId.gender] ? instance.points : this.scoreGenderData.score[instance.characterId.gender] + instance.points;

            this.scoreChartRawData.push(instance.points);

            instance.flow.selectedOptions?.forEach(option => {
                instance.flow.nodes.forEach(node => {
                    node.metadata?.fields?.forEach(field => {
                        if (field.fieldId === option.fieldId) {
                            if (field.fieldTags !== '') {
                                field.fieldTags.forEach(tag => {
                                    this.strategiesData[tag] = (!this.strategiesData[tag] ? 1 : this.strategiesData[tag]) + 1;
                                });
                            }
                            return;
                        }
                    });
                });
            });

            let plugins;

            plugins = [
                {
                    beforeInit: (chart) => {
                        //image.src = images[0];
                    },
                    beforeDraw: (chart) => {
                        var ctx = chart.ctx;
                        var xAxis = chart.scales["x"];
                        var yAxis = chart.scales["y"];
                        xAxis.ticks.forEach((value, index) => {
                            let image = new Image();
                            image.src = this.timeSpentPerScoreData.characters[index] ? this.timeSpentPerScoreData.characters[index] : 'assets/images/profile.png';
                            var x = xAxis.getPixelForTick(index);
                            ctx.drawImage(image, x - 15, yAxis.bottom + 23, 30, 30);

                        });
                    },
                },
            ];
            this.timeSpentPlugin = plugins;
        });
    }

    private getTimeSpentPerScore() {
        this.timeSpentChartData.datasets = [{
            label: this.translate.instant('Time Spent'),
            data: this.timeSpentPerScoreData.timeSpent,
        },
        {
            label: this.translate.instant('Score'),
            data: this.scoreChartRawData,
        }];

        this.timeSpentChartData.labels = this.timeSpentPerScoreData.dates;

        // this.timeSpentChartOptions = {
        //     series: [
        //         {
        //             name: this.translate.instant("Time Spent"),
        //             data: this.timeSpentPerScoreData.timeSpent
        //         },
        //         {
        //             name: this.translate.instant("Score"),
        //             data: this.scoreChartData
        //         }
        //     ],
        //     legend: {
        //         position: 'top',
        //         horizontalAlign: 'center',
        //         floating: false,
        //         show: true
        //     },
        //     chart: {
        //         height: 350,
        //         type: "area"
        //     },
        //     dataLabels: {
        //         enabled: false
        //     },
        //     stroke: {
        //         curve: "smooth"
        //     },
        //     xaxis: {
        //         type: "category",
        //         categories: this.timeSpentPerScoreData.dates,
        //         labels: {
        //             formatter: (value, index) => {
        //                 return this.timeSpentPerScoreData.characters[this.timeSpentPerScoreData.dates.indexOf(value)];
        //             }
        //         }
        //     },
        //     tooltip: {
        //         x: {
        //             format: "dd/MM/yy"
        //         }
        //     }
        // };
    }

    private getScoreGender() {
        let data = [];
        let categories = [];
        if (this.scoreGenderData.count && Object.keys(this.scoreGenderData.count).length) {
            Object.keys(this.scoreGenderData.count).forEach(key => {
                data.push((this.scoreGenderData.score[key] / this.scoreGenderData.count[key]).toFixed(2));
                categories.push(this.translate.instant(key));
            });
        }

        this.scoreGenderChartData.datasets[0].data = data;
        this.scoreGenderChartData.labels = categories;

        // this.scoreGenderChartOptions = {
        //     series: [{
        //         name: this.translate.instant('Score'),
        //         data: data
        //     }],
        //     chart: {
        //         type: "bar",
        //         height: 350
        //     },
        //     plotOptions: {
        //         bar: {
        //             distributed: true,
        //             columnWidth: "55%"
        //         }
        //     },
        //     dataLabels: {
        //         enabled: false
        //     },
        //     stroke: {
        //         show: true,
        //         width: 2,
        //         colors: ["transparent"]
        //     },
        //     fill: {
        //         opacity: 1
        //     },
        //     legend: {
        //         show: false
        //     },
        //     xaxis: {
        //         categories: categories,
        //     },
        // };
    }
}