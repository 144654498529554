import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-flow-character-selection-modal',
    templateUrl: './flow-character-selection-modal.component.html',
    styleUrls: ['./flow-character-selection-modal.component.scss']
})
export class FlowCharacterSelectionDialogComponent implements OnInit {

    public characterList = [];
    public primaryCharacter = [];
    public selectedOpeningAct = [];
    public openingActList = [];
    public showStepCharacters: boolean = false;
    public showStepOpeningAct: boolean = false;
    public isCharactersDisabled: boolean = false;
    public isOpeningActDisabled: boolean = false;

    constructor(public dialogRef: MatDialogRef<FlowCharacterSelectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.characterList = data.characterList;
        this.openingActList = data.openingActs;
        this.showStepCharacters = data.showStepCharacters;
        this.showStepOpeningAct = data.showStepOpeningAct;
    }

    ngOnInit(): void {

    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );

            if (this.primaryCharacter.length >= 1) {
                this.isCharactersDisabled = true;
            }
            else
                this.isCharactersDisabled = false;

            if (this.selectedOpeningAct.length >= 1) {
                this.isOpeningActDisabled = true;
            }
            else
                this.isOpeningActDisabled = false;
        }
    }

    randomizeCharacters() {
        this.primaryCharacter = [this.characterList[Math.floor(Math.random() * this.characterList.length)]];
        this.isCharactersDisabled = true;
    }

    randomizeOpeningActs() {
        this.selectedOpeningAct = [this.openingActList[Math.floor(Math.random() * this.openingActList.length)]];
        this.isOpeningActDisabled = true;
    }

    startTraining() {
        this.dialogRef.close([this.primaryCharacter[0], this.selectedOpeningAct[0]]);
    }
}