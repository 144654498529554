<div fxLayout="column" style="margin: 1vh 2vw;">
    <div fxLayout="row" fxLayoutGap="0.5vh" class="time" fxFlex>
        <div fxFlex *ngIf="instance.createdAt" fxLayoutAlign="center">
            {{instance.createdAt | date: 'medium'}}
        </div>
        <!-- <div fxFlex fxLayoutAlign="end" *ngIf="instance.updatedAt">
            {{'Last Updated:' | translate}} {{instance.updatedAt | date: 'medium'}}
        </div> -->
    </div>
    <div fxFlex class="steps" fxLayout="row" fxLayoutGap="2vw">
        <div fxFlex="50" class="step"
            [ngStyle]="{'background-image': step.background ? 'url(' + step.background + ')': 'unset'}"
            fxLayout="column" fxLayoutGap="1vh">
            <div class="content" fxFlex fxLayout="row" fxLayoutGap="1vw">
                <div fxflex *ngIf="character">
                    <img
                        [src]="node?.type === 'condition' ? currentUser.pictureLink || 'assets/images/profile.png' : character.pictureLink || 'assets/images/profile.png'">
                </div>
                <div fxFlex fxLayoutAlign="center start" fxLayout="column" fxLayoutGap="2vh">
                    <div fxFlex>
                        <div *ngIf="step.content">{{step.content}}</div>
                        <div *ngIf="step.options && instance.status !== 'completed'" class="options">
                            <mat-radio-group [(ngModel)]="selectedOption.fieldId">
                                <mat-radio-button *ngFor="let option of step.options" [value]="option.fieldId">
                                    {{option.label}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <div fxFlex class="reflection"
                        *ngIf="instance.allowReflection && selectedOption.fieldId && !isPreview">
                        <mat-form-field appearance="fill" style="width: 100%;">
                            <mat-label>{{'Reflect on your answer' | translate}}</mat-label>
                            <textarea matInput [(ngModel)]="selectedOptionReflection"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="audio" *ngIf="step.audio" fxFlex fxLayout="row" fxLayoutAlign="end">
                <audio controls="" controlslist="nodownload" style="width: 14vw; height: 5vh;"
                    [src]="step.audio"></audio>
            </div>
            <div class="video" *ngIf="step.video" fxFlex fxLayout="row" fxLayoutAlign="center">
                <video width="320" height="240" controls>
                    <source [src]="step.video" type="video/mp4">
                </video>
            </div>
            <div class="buttons" fxFlex fxLayout="row" *ngIf="node" fxLayoutAlign="end end">
                <div fxFlex fxGrow="0" style="margin-left: 5vw;">
                    <button mat-mini-fab (click)="previous()" *ngIf="previousNodeId && node.type !== 'start'"
                        [title]="'Previous' | translate">
                        <mat-icon>navigate_before</mat-icon>
                    </button>
                </div>
                <div fxFlex style="margin-right: 5vw;">
                    <button mat-mini-fab (click)="next()" *ngIf="node.type !== 'end'" [title]="'Next' | translate">
                        <mat-icon>navigate_next</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div fxFlex *ngIf="instance.status === 'completed' && node?.type === 'condition'" fxLayout="column"
            fxLayoutGap="2vh">
            <span fxFlex fxGrow="0" style="font-size: 10pt; font-weight: 500;">{{'Your Response' | translate}}:</span>
            <div fxFlex fxLayout="column" fxLayoutGap="1vh">
                <ng-container *ngFor="let option of step.options">
                    <mat-radio-button fxFlex fxGrow="0" [disabled]="true" [value]="option.fieldId"
                        [checked]="selectedOption?.fieldId === option.fieldId">
                        {{option.label}}
                    </mat-radio-button>
                    <div fxFlex fxLayout="row" fxLayoutGap="1vw" fxGrow="0">
                        <div fxFlex class="score" fxGrow="0">{{option.score}} {{'Points' |
                            translate}}</div>
                        <div fxFlex fxGrow="0" *ngFor="let tag of option.tags" class="tag">
                            {{tag}}
                        </div>
                    </div>
                    <div class="reflection"
                        *ngIf="selectedOption && isPreview && selectedOption.fieldId === option.fieldId" fxFlex
                        fxGrow="0">
                        <mat-form-field appearance="fill" style="width: 100%;">
                            <mat-label>{{'Reflect on your answer' | translate}}</mat-label>
                            <textarea matInput readonly [(ngModel)]="selectedOptionReflection"></textarea>
                        </mat-form-field>
                    </div>
                </ng-container>
                <div fxFlex fxGrow="0" class="total-score" *ngIf="selectedOption?.score">
                    {{'Your Score' | translate}}: {{selectedOption.score}}
                </div>
                <div fxFlex fxGrow="0" fxLayout="column" fxLayoutGap="1vh" class="feedback"
                    *ngIf="selectedOptionFeedback">
                    <ng-container *ngFor="let option of step.options">
                        <ng-container *ngIf="option.fieldId === selectedOption?.fieldId">
                            <div fxFlex fxLayout="row" fxLayoutGap="0" fxGrow="0">
                                <div *ngFor="let tag of option.tags" class="tag">
                                    {{tag}}
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <div fxFlex>{{selectedOptionFeedback}}</div>
                </div>
            </div>
        </div>
        <div fxFlex *ngIf="node?.type === 'end'">
            <div class="total-score" style="margin-top: 2vh;">
                {{'Your Total Score' | translate}}: {{score}}
            </div>
        </div>
    </div>
</div>