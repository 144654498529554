
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  orgId: '5e85c5820c22d9223a15d489',
  serverUrl: 'https://qa.api.flow.interactivelife.me/scenario/',
  serverHostUrl: 'https://teacher-toolbox.interactivelife.me',
  identityName: 'Training',
  customKeys: {
    roleAdmin: '5eb137f1d3123a407c7c97cc',// Admin
    roleView: '5ee76348d3123a0ef0d8cb63'
  },
  lockSite: false,
  rollbar: {
    apiKey: '64dbe8b3dd6c4a83985c03fc28e6b5e6'
  },
  environment: 'qa',
  casUrl: 'https://qa.api.flow.interactivelife.me/scenario/',
  productId: 'cc0b1284-ac68-4820-9853-48bf18d7c11f',
  s3: 'https://stellar-images-bucket-staging.s3.amazonaws.com/',
};
